body {
    max-width: 100%;
    overflow-x: hidden;

    position: relative;
    background: #fdf9f7 url("/static/images/pattern-back.png") repeat 0 0;

    &:after {
        content: "";
        background: url("/static/images/pattern-front.png") repeat 0 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}

img {
    max-width: 100%;
}

picture {
    display: inline-block;

    img {
        width: 100%;
    }
}

ul {
    padding: 0;
}

a,
a:hover {
    color: inherit;
}

p {
    margin-bottom: 1em;
}

button {
    background: 0;
    border: 0;
}

/* Fix the line break in slide titles */
.glide__slides {
    white-space: normal !important;
    hyphens: auto;
    overflow-wrap: break-word;
}
