.b-multi-container {


    ul {
        li {
            list-style: none;
        }
        li:before {
            content:'';
            display: inline-block;
            height: 1.0em;
            width: 2em;

            background-image: url('/static/images/check.svg');
            background-size:contain;
            background-repeat:no-repeat;
        }
    }
}