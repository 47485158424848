.b-image-and-text {
    margin: 110px 0 150px;

    &__img {
        clip-path: polygon(100% 0, 100% 61%, 75% 100%, 0 100%, 0 0);
        display: block;

        width: 100%;

        &:nth-child(1) {
            padding-left: 20px;
        }

        &:nth-child(2) {
            width: 220px;
            margin-top: -30px;
        }
    }

    h2 {
        margin-top: 1em;
    }

    &__copy {
        margin-bottom: 2em;
    }

    &__link {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        margin: 150px 0;

        h2 {
            margin-top: 37px;
        }

        &__img:nth-child(1) {
            padding-left: 30px;
        }

        &__link {
            width: auto;
        }

        // gutter is 1 column width
        .col-md-6:first-child {
            padding-right: 30px;
        }

        .col-md-6:last-child {
            padding-left: 30px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__img:nth-child(1) {
            padding-left: 80px;
        }

        &__img:nth-child(2) {
            width: 320px;
            margin-top: -160px;
        }
    }

    @include media-breakpoint-up(xl) {
        margin: 200px 0;

        &__img:nth-child(1) {
            padding-left: 90px;
        }

        &__img:nth-child(2) {
            width: 380px;
            margin-top: -180px;
        }
    }

    @include media-breakpoint-up(xxl) {
        margin: 300px 0;

        &__img:nth-child(1) {
            padding-left: 120px;
        }

        &__img:nth-child(2) {
            width: 530px;
            margin-top: -260px;
        }
    }
}
