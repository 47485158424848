// Cut into the left top corner
@mixin clip-left($width) {
    clip-path: polygon(#{$width}px 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 #{$width * 1.7}px);
}

// Cut into the right lower corner
@mixin clip-right($width) {
    clip-path: polygon(
        100% 0,
        100% calc(100% - #{$width * 1.7}px),
        calc(100% - #{$width}px) 100%,
        0 100%,
        0 0
    );
}

// Show a gradient inside a text
@mixin text-gradient {
    display: inline-block;
    background: -webkit-linear-gradient(0deg, $color-blue, $color-pink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
