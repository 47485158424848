.b-service-cards {
    @include media-breakpoint-up(lg) {
        &__item {
            /* Two items */
            &[class*="-6"]:nth-child(2n-1) {
                margin-top: -3rem;
                padding-right: 3rem;
            }
            &[class*="-6"]:nth-child(2n+0) {
                margin-top: 3rem;
                padding-left: 3rem;
            }
            
            /* Three items */
            &[class*="-4"]:nth-child(3n-2) {
                margin-top: -6rem;
                padding-right: 3rem;
            }
            &[class*="-4"]:nth-child(3n-1) {
                margin-top: 0;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            &[class*="-4"]:nth-child(3n+0) {
                margin-top: 6rem;
                padding-left: 3rem;
            }
        }
    }
}
