.c-carousel-card {
    position: relative;
    opacity: 0.4;
    transition: opacity 0.3s;

    &.glide__slide--active,
    &.glide__slide--active ~ & {
        opacity: 1;
    }

    &__image {
        @include clip-right(55);
        width: 100%;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 33px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        &:hover {
            text-decoration: none;

            h3 {
                text-decoration: underline;
            }
        }

        h3,
        h5 {
            color: #fff;
            white-space: normal;
        }

        h3 {
            margin-right: 20%;
            margin-bottom: 0;
        }
    }

    &__link {
        position: absolute;
        bottom: 33px;
        right: 0;
        width: 74px;

        img {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            padding: 51px;
        }

        &__image {
            @include clip-right(85);
        }

        &__link {
            width: 80px;
            bottom: 51px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__content {
            padding: 60px;
        }

        &__image {
            @include clip-right(102);
        }

        &__link {
            width: 94px;
            bottom: -60px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__content {
            padding: 80px;
        }

        &__image {
            @include clip-right(142);
        }

        &__link {
            width: 132px;
            bottom: 80px;
        }
    }
}
