// Use this file to override all bootstrap default variables, all variables
// which can be overridden here are listed in this file:
// https://raw.githubusercontent.com/twbs/bootstrap/v4-dev/scss/_variables.scss

/*
    Note: naming is slightly different from Sketch files

    here:       in sketch:
    ----------------------------
    xxs         "mobile"
    xs          "mobile"
    sm          "mobile"
    md          "tablet portrait"
    lg          "tablet landscape"
    xl          "desktop"
    xxl         "desktop xl"        <!-- xxl/xl difference
*/
$grid-breakpoints: (
    xxs: 0,
    xs: 375px,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1440px,
    xxl: 1900px,
);

// Container max width (+30px to compensate for padding)
$container-max-widths: (
    xs: 370px,
    sm: 570px,
    md: 738px,
    lg: 994px,
    xl: 1170px,
    xxl: 1630px,
);
