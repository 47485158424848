.b-header {
    &:before {
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        background: linear-gradient(73deg, $color-blue 2%, $color-pink 100%);
    }

    &__inner {
        position: relative;
        height: $header-height-sm;
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 120px;
    }

    &__menu {
        list-style: none;
    }
    &__nl {
        width: 20px;
        height: 20px;
        background: url("/static/images/flag_nl.png") no-repeat center center;
        background-size: contain;
        margin-left: auto;
    }
    &__en {
        width: 20px;
        height: 20px;
        background: url("/static/images/flag_en.png") no-repeat center center;
        background-size: contain;
        margin-left: 5px;
    }
    &__submenu-title,
    &__menu-item > a,
    &__submenu-item .title,
    &__contact-us {
        font-family: $font-family-heading;
        text-transform: uppercase;
        color: $color-deep-blue;
    }
}

// Mobile and tablet portrait
@include media-breakpoint-down(md) {
    .b-header {
        &__contact-us {
            display: none;
        }

        &__language {
            display: none;
        }

        &__toggle-menu {
            width: 35px;
            height: 21px;
            background: url("/static/images/hamburger.svg") no-repeat center center;
            background-size: contain;
            margin-left: auto;

            &:focus {
                outline: 0;
            }

            .menu-open & {
                background-image: url("/static/images/cross.svg");
            }
        }

        &__menu {
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-item {
                margin: 17px 0;
                font-size: map-get($font-sizes-sm, heading-4);
                line-height: map-get($line-heights-sm, heading-4);

                &.highlight {
                    color: $color-red;
                }
            }
        }

        &__submenu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 23px 0;
            text-align: center;

            .icon,
            .description {
                display: none;
            }

            &-title {
                font-size: 14px;
                line-height: 24px;
                opacity: 0.8;
            }

            &-item {
                margin: 7px 0;
                font-size: map-get($font-sizes-sm, heading-4);
                line-height: map-get($line-heights-sm, heading-4);
            }
        }

        .menu-open & {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            background: #fff;
            z-index: 10;
            height: 100vh;
            overflow-y: scroll;

            &__menu {
                display: flex;
                position: absolute;
                top: 100px;
                left: 0;
                width: 100%;
            }
        }
    }
}

// Tablet portrait only
@include media-breakpoint-only(md) {
    .b-header {
        &__logo {
            width: 134px;
        }

        &__menu {
            &-item {
                margin: 23px 0;
                font-size: map-get($font-sizes-md, heading-3);
                line-height: map-get($line-heights-md, heading-3);
            }
        }
    }
}

// Tablet landscape and bigger
@include media-breakpoint-up(lg) {
    .b-header {
        &__toggle-menu {
            display: none;
        }

        &__inner {
            height: $header-height-lg;
        }

        &__menu {
            display: flex;
            margin: 0 auto;
            font-size: 14px;
            height: $header-height-lg;

            &-item {
                margin: 0 15px;
                display: flex;
                align-items: center;
            }
        }

        &__submenu {
            cursor: pointer;

            &-inner {
                position: absolute;
                top: $header-height-lg;
                left: 50%;
                width: 100vw;
                transform: translate(-50%);
                background: #fff;
                padding-bottom: 100px;

                .container {
                    display: flex;
                }
            }

            &-item {
                border: 1px solid transparent;
                transition: border 0.2s;
                padding: 15px;

                &:hover {
                    border-color: #d2d0f5;
                    text-decoration: none;

                    .title {
                        color: $color-red;
                    }
                }

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            &-title {
                position: relative;
                padding-right: 20px;

                &:before,
                &:after {
                    content: url("/static/images/chevron-down.svg");
                    position: absolute;
                    right: 0;
                    top: -1px;
                }
                &:after {
                    content: url("/static/images/chevron-up.svg");
                    opacity: 0;
                }
            }

            .icon {
                opacity: 0.6;
                width: auto;
                height: 34px;
                max-height: 34px;
            }

            .title {
                display: block;
                font-size: 16px;
                margin: 20px 0;

                &:after {
                    content: url("/static/images/arrow-right-short.svg");
                    top: 2px;
                    position: relative;
                    margin-left: 6px;
                }
            }

            &-item:not(:hover) .title {
                @include text-gradient;
                display: block;
            }

            &.open &-title {
                @include text-gradient;

                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        // Hide submenus that are closed
        &__submenu:not(.open) &__submenu-inner {
            display: none;
        }

        &__contact-us {
            background: url("/static/images/slash.svg") no-repeat center left;
            padding-left: 25px;
            font-size: 14px;

            &,
            &:hover {
                color: $color-red;
            }
        }

        // When a submenu is open, show a white backgound on the header
        .submenu-open & {
            background: #fff;
            z-index: 10;
            position: relative;
        }
    }
}

// Desktop
@include media-breakpoint-up(xl) {
    .b-header {
        &__logo {
            width: 155px;
        }
    }
}
// "Desktop xl"
@include media-breakpoint-up(xxl) {
    .b-header {
        &__logo {
            width: 180px;
        }

        &__submenu-inner .container {
            max-width: 1280px;
        }

        &__menu,
        &__contact-us {
            font-size: 16px;
        }
    }
}
