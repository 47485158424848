.b-title {
    min-height: 100px;
    padding-top: 100px;
    display: flex;
    align-items: center;

    &__subtitle {
        font-weight: 200;
    }

    &__titles {
        position: relative;
    }

    &.animation &__title {
        .inner {
            opacity: 0;
            transform: translateX(-20%);
            transition: transform 1s, opacity 1.5s;
        }

        &:nth-child(1),
        &:nth-child(1) .inner {
            clip-path: polygon(0 0, 25% 0, calc(25% - 16.5%) 100%, 0 100%);
        }

        &:nth-child(2),
        &:nth-child(2) .inner {
            clip-path: polygon(25% 0, 50% 0, calc(50% - 16.5%) 100%, calc(25% - 16.5%) 100%);
            transition-delay: 0.4s;
        }

        &:nth-child(3),
        &:nth-child(3) .inner {
            clip-path: polygon(50% 0, 75% 0, calc(75% - 16.5%) 100%, calc(50% - 16.5%) 100%);
            transition-delay: 0.8s;
        }

        &:nth-child(4),
        &:nth-child(4) .inner {
            clip-path: polygon(75% 0, 100% 0, 100% 100%, calc(75% - 16.5%) 100%);
            transition-delay: 1.2s;
        }

        &:not(:first-child) {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__titles.start-animation &__title .inner {
        opacity: 1;
        transform: translateX(0);
    }

    &__scroll {
        position: absolute;
        top: 100vh;
        right: 0;
        width: 90px;
        height: 64px;
        transform: translateY(-100%);

        &::before {
            content: "";
            display: block;
            opacity: 1;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            clip-path: polygon(37% 0, 0% 100%, 1px 100%, calc(37% + 1px) 1px, 100% 1px, 100% 0%);
            background: $color-red;

            .no-clip & {
                background: none;
            }
        }

        img {
            width: 15px;
            position: relative;
            bottom: -9px;
            left: 13px;
        }

        &:focus {
            outline: 0;
        }
    }

    // Fix for small devices
    @include media-breakpoint-down(xxs) {
        h1 {
            font-size: 59px;
            line-height: 1;
        }
    }

    @include media-breakpoint-up(xl) {
        min-height: 100px;

        &__scroll {
            width: 120px;
            height: 85px;

            img {
                width: 17px;
                bottom: -10px;
                left: 4px;
            }

            &::before {
                clip-path: polygon(
                    37% 0,
                    0% 100%,
                    2px 100%,
                    calc(37% + 1.4px) 1.4px,
                    100% 1.4px,
                    100% 0%
                );
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        &__scroll {
            width: 160px;
            height: 120px;

            img {
                width: 28px;
                bottom: -17px;
                left: 4px;
            }

            &::before {
                clip-path: polygon(
                    37% 0,
                    0% 100%,
                    3px 100%,
                    calc(37% + 2px) 2px,
                    100% 2px,
                    100% 0%
                );
            }
        }
    }
}
