.b-image-and-quote {
    h2 {
        margin-bottom: 14px;
        margin-left: 10px;
    }
    img{
        margin-bottom: 20px;
    }

    @include media-breakpoint-up(md) {
        h2 {
            bottom: -70px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
        img{
            margin-bottom: 0px;
        }
    }
    @include media-breakpoint-up(xl) {
        h2 {
            bottom: -90px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
        img{
            margin-bottom: 0px;
        }
    }
    @include media-breakpoint-up(xxl) {
        h2 {
            bottom: -90px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
        img{
            margin-bottom: 0px;
        }
    }
}
