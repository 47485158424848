.b-carousel {
    h2 {
        margin-bottom: 14px;
        margin-left: 10px;
    }

    .glide__bullets {
        margin-left: 30px;
    }

    .glide__bullet {
        height: 6px;
        background: #cfccd0;
        width: 10px;
        transition: all 0.3s;
        border-radius: 2px;

        &--active {
            width: 22px;
            background: #1d1c34;
        }

        &:focus {
            outline: 0;
        }
    }

    @include media-breakpoint-up(md) {
        h2 {
            bottom: -37px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }

        .glide__bullets {
            margin-left: 120px;
        }
    }

    @include media-breakpoint-up(lg) {
        .glide__bullets {
            margin-left: 140px;
        }
    }

    @include media-breakpoint-up(lg) {
        .glide__bullets {
            margin-left: 140px;
        }
    }

    @include media-breakpoint-up(xl) {
        .glide__bullets {
            margin-left: 230px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .glide__bullets {
            margin-left: 300px;
        }
    }
}
