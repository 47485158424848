body {
    color: $color-deep-purple;
    font-family: $font-family-body;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $font-family-heading;
    text-transform: uppercase;
    margin-bottom: 0.8em;
    color: $color-deep-blue;
    font-weight: 400;
}

.gradient {
    @include text-gradient;
}

h1,
.h1 {
    font-weight: 200;
}

.h1-xl {
    @include text-gradient;
    font-weight: 200;
    letter-spacing: 0.85px;
}

.h1-l {
    @include text-gradient;
    font-weight: 200;
    letter-spacing: 0.85px;
}


h2,
.h2 {
    font-weight: 200;
}

.text-paragraph {
    letter-spacing: 0.53px;
}

.text-small {
    letter-spacing: 0.6px;
}

.text-subtitle {
    letter-spacing: 0.6px;
}
/*
    Below is only styling for font-size and line-height
*/
body {
    font-size: map-get($font-sizes-sm, paragraph);
    line-height: map-get($line-heights-sm, paragraph);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, paragraph);
        line-height: map-get($line-heights-md, paragraph);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, paragraph);
        line-height: map-get($line-heights-lg, paragraph);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, paragraph);
        line-height: map-get($line-heights-xl, paragraph);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, paragraph);
        line-height: map-get($line-heights-xxl, paragraph);
    }
}

.text-lead {
    font-size: map-get($font-sizes-sm, lead);
    line-height: map-get($line-heights-sm, lead);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, lead);
        line-height: map-get($line-heights-md, lead);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, lead);
        line-height: map-get($line-heights-lg, lead);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, lead);
        line-height: map-get($line-heights-xl, lead);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, lead);
        line-height: map-get($line-heights-xxl, lead);
    }
}

.text-small {
    font-size: map-get($font-sizes-sm, small);
    line-height: map-get($line-heights-sm, small);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, small);
        line-height: map-get($line-heights-md, small);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, small);
        line-height: map-get($line-heights-lg, small);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, small);
        line-height: map-get($line-heights-xl, small);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, small);
        line-height: map-get($line-heights-xxl, small);
    }
}

.text-intro {
    font-size: map-get($font-sizes-sm, intro);
    line-height: map-get($line-heights-sm, intro);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, intro);
        line-height: map-get($line-heights-md, intro);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, intro);
        line-height: map-get($line-heights-lg, intro);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, intro);
        line-height: map-get($line-heights-xl, intro);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, intro);
        line-height: map-get($line-heights-xxl, intro);
    }
}

h1,
.h1 {
    font-size: map-get($font-sizes-sm, heading-1);
    line-height: map-get($line-heights-sm, heading-1);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, heading-1);
        line-height: map-get($line-heights-md, heading-1);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, heading-1);
        line-height: map-get($line-heights-lg, heading-1);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, heading-1);
        line-height: map-get($line-heights-xl, heading-1);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, heading-1);
        line-height: map-get($line-heights-xxl, heading-1);
    }
}

.h1-xl {
    font-size: map-get($font-sizes-sm, heading-2-xl);
    line-height: map-get($line-heights-sm, heading-2-xl);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, heading-1-xl);
        line-height: map-get($line-heights-md, heading-1-xl);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, heading-1-xl);
        line-height: map-get($line-heights-lg, heading-1-xl);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, heading-1-xl);
        line-height: map-get($line-heights-xl, heading-1-xl);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, heading-1-xl);
        line-height: map-get($line-heights-xxl, heading-1-xl);
    }
}

h2,
.h2 {
    font-size: map-get($font-sizes-sm, heading-2);
    line-height: map-get($line-heights-sm, heading-2);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, heading-2);
        line-height: map-get($line-heights-md, heading-2);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, heading-2);
        line-height: map-get($line-heights-lg, heading-2);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, heading-2);
        line-height: map-get($line-heights-xl, heading-2);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, heading-2);
        line-height: map-get($line-heights-xxl, heading-2);
    }
}

h3,
.h3 {
    font-size: map-get($font-sizes-sm, heading-3);
    line-height: map-get($line-heights-sm, heading-3);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, heading-3);
        line-height: map-get($line-heights-md, heading-3);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, heading-3);
        line-height: map-get($line-heights-lg, heading-3);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, heading-3);
        line-height: map-get($line-heights-xl, heading-3);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, heading-3);
        line-height: map-get($line-heights-xxl, heading-3);
    }
}

h4,
.h4 {
    font-size: map-get($font-sizes-sm, heading-4);
    line-height: map-get($line-heights-sm, heading-4);

    @include media-breakpoint-up(md) {
        font-size: map-get($font-sizes-md, heading-4);
        line-height: map-get($line-heights-md, heading-4);
    }

    @include media-breakpoint-up(lg) {
        font-size: map-get($font-sizes-lg, heading-4);
        line-height: map-get($line-heights-lg, heading-4);
    }

    @include media-breakpoint-up(xl) {
        font-size: map-get($font-sizes-xl, heading-4);
        line-height: map-get($line-heights-xl, heading-4);
    }

    @include media-breakpoint-up(xxl) {
        font-size: map-get($font-sizes-xxl, heading-4);
        line-height: map-get($line-heights-xxl, heading-4);
    }
}
