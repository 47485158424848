.b-quote {
    margin: 40px 0 90px;
    &__image{
        background: url("/static/images/quote.svg") no-repeat 0px 5px;
        min-height: 100px;
    }
    &__text {
        margin-bottom: 30px;
        padding-left: 100px;
        font-weight: 400;
    }

    &__quotee {
        font-weight: 400;
    }

    h2 {
        margin-bottom: 14px;
        margin-left: 10px;
    }

    @include media-breakpoint-down(sm) {
        &__image{
            margin-left: 5%;
            margin-right: 5%;
        }
        margin-left: 5%;
        margin-right: 5%;

        h2 {
            bottom: -37px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
    }

    @include media-breakpoint-up(md) {
        &__text {
            margin-bottom: 20px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__card {
            @include clip-right(56);
        }
    }

    @include media-breakpoint-up(xl) {
        &__card {
            @include clip-right(66);
        }
    }

    @include media-breakpoint-up(xxl) {
        &__card {
            @include clip-right(92);
        }
    }
}
