.c-service-card {
    background-color: transparent;
    display: block;
    filter: drop-shadow(0 22px 42px rgba(#1a0f84, 0.05)); // Filter works nicely with clip-path
    padding: 33px 54px 54px 33px;
    position: relative;

    h4 {
        font-weight: 200;
        hyphens: auto;
        overflow-wrap: break-word;
    }

    ul {
        list-style-position: inside;
        list-style: none;

        li {
            margin-bottom: 1em;
            padding-left: 1.5em;
            position: relative;

            &:after {
                content: '';
                height: .8em;
                width: .8em;
                background: #FFFFFF;
                border: solid #F0106F 3px;
                display: block;
                position: absolute;
                transform: rotate(45deg);
                top: .25em;
                left: 0;
            }
        }
    }

    &:before {
        @include clip-right(54);
        position: absolute;
        background-color: #ffffff;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &__symbol {
        margin-bottom: 10px;

        img {
            opacity: 0.6;
        }
    }

    &__link {
        position: absolute;
        right: 0;
        bottom: 33px;
        color: $color-red;
        font-size: 12px;
        font-family: $font-family-heading;
        text-transform: uppercase;
    }
    
    &:hover {
        text-decoration: none;

        .c-service-card__link {
            text-decoration: underline;
        }
    }
    
    @include media-breakpoint-up(md) {
    }
    
    @include media-breakpoint-up(lg) {
    }
    
    @include media-breakpoint-up(xl) {
    }
    
    @include media-breakpoint-up(xxl) {
    }
}
