.button {
    &__primary,
    &__secondary {
        text-transform: uppercase;
        padding: 18px 50px;
        position: relative;
        letter-spacing: 0.6px;
        font-size: 12px;
        font-weight: 600;
        font-family: $font-family-heading;
        display: inline-block;
        text-align: center;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &:hover::before {
            background: $color-brown;
        }

        &:before {
            transition: all 0.3s;
        }

        @include media-breakpoint-up(xl) {
            padding: 25px 50px;
            font-size: 16px;
        }
    }

    &__primary {
        color: #fff;
        filter: drop-shadow(0 20px 40px rgba(255, 61, 61, 0.2));

        &:hover {
            cursor: pointer;
            color: #fff;
        }

        &::before {
            content: "";
            background: $color-red;

            display: block;
            opacity: 1;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            @include clip-right(18.6);
        }
    }

    &__secondary {
        &::before {
            content: "";
            background: $color-red;
            display: block;
            opacity: 1;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;

            clip-path: polygon(
                0 0,
                100% 0,
                100% calc(100% - 31.62px),
                calc(100% - 18.6px) 100%,
                0 100%,
                0 0px,
                2px 1px,
                2px calc(100% - 4px),
                calc(100% - 18.6px) calc(100% - 4px),
                calc(100% - 2px) calc(100% - 31.62px),
                calc(100% - 2px) 2px,
                0 2px
            );

            .no-clip & {
                background: none;
                border: 1px solid $color-red;
            }
        }
    }
}
