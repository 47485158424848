$color-blue: #2411dc;
$color-pink: #f0106f;
$color-red: #ff3d3d;
$color-brown: #ae3d3f;
$color-deep-purple: #3e3b61;
$color-deep-blue: #1e1b36;

$font-family-heading: "Tomorrow", sans-serif;
$font-family-body: "Exo 2", sans-serif;

$header-height-sm: 80px;
$header-height-lg: 130px;

// (in sketch Fonts layer: "mobile")
$font-sizes-sm: (
    lead: 22px,
    intro: 18px,
    paragraph: 16px,
    small: 14px,
    heading-1-xl: 68px,
    heading-1-l: 55px,
    heading-1: 42px,
    heading-2: 42px,
    heading-3: 29px,
    heading-4: 22px,
    heading-5: 18px,
    heading-6: 16px,
);

$line-heights-sm: (
    lead: 28px,
    intro: 24px,
    paragraph: 20px,
    small: 20px,
    heading-1-xl: 70px,
    heading-1-l: 58px,
    heading-1: 45px,
    heading-2: 45px,
    heading-3: 33px,
    heading-4: 26px,
    heading-5: 27px,
    heading-6: 24px,
);

// md (in sketch Fonts layer: "tablet")
$font-sizes-md: (
    lead: 24px,
    intro: 20px,
    paragraph: 16px,
    small: 14px,
    heading-1-xl: 68px,
    heading-1-l: 58px,
    heading-1: 48px,
    heading-2: 48px,
    heading-3: 33px,
    heading-4: 24px,
    heading-5: 19px,
    heading-6: 16px,
);
$line-heights-md: (
    lead: 32px,
    intro: 30px,
    paragraph: 24px,
    small: 20px,
    heading-1-xl: 75px,
    heading-1-l: 65px,
    heading-1: 54px,
    heading-2: 54px,
    heading-3: 37px,
    heading-4: 32px,
    heading-5: 24px,
    heading-6: 24px,
);

// lg (tablet 'portrait' sizes are the same as 'landscape' sizes)
$font-sizes-lg: $font-sizes-md;
$line-heights-lg: $line-heights-md;

// xl (in sketch Fonts layer: "desktop")
$font-sizes-xl: (
    lead: 29px,
    intro: 24px,
    paragraph: 18px,
    small: 14px,
    heading-1-xl: 108px,
    heading-1-l: 83px,
    heading-1: 57px,
    heading-2: 57px,
    heading-3: 39px,
    heading-4: 29px,
    heading-5: 23px,
    heading-6: 16px,
);

$line-heights-xl: (
    lead: 38px,
    intro: 32px,
    paragraph: 27px,
    small: 20px,
    heading-1-xl: 121px,
    heading-1-l: 93px,
    heading-2: 64px,
    heading-3: 44px,
    heading-4: 38px,
    heading-5: 28px,
    heading-6: 24px,
);

// xxl (in sketch Fonts layer: "desktop xl")
$font-sizes-xxl: (
    lead: 40px,
    intro: 32px,
    paragraph: 24px,
    small: 16px,
    heading-1-xl: 150px,
    heading-1-l: 120px,
    heading-1: 80px,
    heading-2: 80px,
    heading-3: 54px,
    heading-4: 40px,
    heading-5: 32px,
    heading-6: 20px,
);

$line-heights-xxl: (
    lead: 54px,
    intro: 42px,
    paragraph: 32px,
    small: 22px,
    heading-1-xl: 170px,
    heading-1-l: 128px,
    heading-1: 85px,
    heading-2: 85px,
    heading-3: 62px,
    heading-4: 54px,
    heading-5: 40px,
    heading-6: 30px,
);
