.b-generic-text {
    margin: 40px 0 10px;
    ul {
        list-style-position: inside;
        list-style: none;

        li {
            margin-bottom: 1em;
            padding-left: 1.5em;
            position: relative;

            &:after {
                content: '';
                height: .8em;
                width: .8em;
                background: #FFFFFF;
                border: solid #F0106F 3px;
                display: block;
                position: absolute;
                transform: rotate(45deg);
                top: .25em;
                left: 0;
            }
        }
    }
    &__text {
        margin-bottom: 0px;
    }

    &__copy {
        margin-bottom: 40px;
    }

    &__cards {
        display: flex;
        align-items: flex-start;
    }

    &__card {
        @include clip-right(41);
    }

    @include media-breakpoint-down(sm) {
        margin-left: 5%;
        margin-right: 5%;
        .button__secondary {
            width: 100%;
        }

        &__cards {
            flex-direction: column;
        }

        &__card {
            width: 210px;

            &:nth-child(2) {
                align-self: flex-end;
                margin-top: -90px;
            }

            &:nth-child(3) {
                margin-top: -85px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__text {
            margin-bottom: 0px;
        }

        &__card {
            flex: 1;
            margin-right: 4%;

            &:nth-child(1) {
                margin-top: 90px;
            }

            &:nth-child(2) {
                margin-top: 200px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__card {
            @include clip-right(56);
        }
    }

    @include media-breakpoint-up(xl) {
        &__card {
            @include clip-right(66);
        }
    }

    @include media-breakpoint-up(xxl) {
        &__card {
            @include clip-right(92);
        }
    }
}
