.b-footer {

    &__top {
        background-repeat: no-repeat;
        background: linear-gradient(to bottom right, #1f1868, #3b1759);
        padding: 90px 0 45px;
        @include clip-left(78);
    }

    &__copy {
        position: relative;
        max-width: 250px;
        left: 10px;
        margin: 0 auto;
    }

    &__logo {
        width: 120px;
    }

    h2,
    .label {
        color: #fff;
        text-transform: uppercase;
    }

    &__copy .h4 {
        display: block;
        color: $color-red;
        font-weight: 400;
    }

    &__logos {
        border-top: 0.5px solid rgba(#ffffff, 0.2);
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 75px;

        &-text {
            color: #fff;
            text-transform: uppercase;
            margin: 30px 0 20px;
        }
    }

    &__social a {
        margin: 0 17px;
        text-decoration: none;
    }

    &__bottom {
        text-align: center;
        padding-bottom: 55px;

        a {
            margin: 0 15px;
            display: inline-block;
        }
    }

    &__copyright {
        margin: 46px 0 30px;
        display: inline-block;
    }

    @include media-breakpoint-up(md) {
        padding-top: 20px;

        h2 {
            margin-bottom: 38px;
        }

        &__top {
            @include clip-left(100);
            padding-top: 155px;
        }

        &__copy {
            left: 0;
            max-width: 465px;
        }

        &__logos {
            flex-direction: row;
            margin-top: 110px;

            &-text {
                margin: 0 10px 0 auto;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 90px;
            padding-bottom: 0;
        }

        &__copyright {
            margin: 0;
        }

        &__legal {
            margin-left: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 30px;
        &__copy {
            max-width: 636px;
        }

        &__logos {
            flex-direction: row;
            margin-top: 150px;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: 40px;
        h2 {
            margin-bottom: 64px;
        }

        &__top {
            @include clip-left(168);
            padding-top: 180px;
        }

        &__logos {
            margin-top: 165px;
        }

        &__copy {
            left: -75px;
            max-width: 610px;
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-top: 50px;
        h2 {
            margin-bottom: 92px;
        }

        &__top {
            @include clip-left(234);
            padding-top: 255px;
        }

        &__copy {
            left: -110px;
            max-width: 855px;
        }
    }
}
