.b-image {
    margin-bottom: 40px;
    h2 {
        margin-bottom: 14px;
        margin-left: 10px;
    }
    img{
        margin-bottom: 20px;
    }

    @include media-breakpoint-up(md) {
        h2 {
            bottom: -40px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
        img{
            margin-bottom: 0px;
        }
    }
    @include media-breakpoint-up(xl) {
        h2 {
            bottom: -45px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
        img{
            margin-bottom: 0px;
        }
    }
    @include media-breakpoint-up(xxl) {
        h2 {
            bottom: -55px;
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
        img{
            margin-bottom: 0px;
        }
    }
}
.c-image-card {
    position: relative;
    opacity: 0.4;
    transition: opacity 0.3s;

    &.glide__slide--active,
    &.glide__slide--active ~ & {
        opacity: 1;
    }

    &__image {
        @include clip-right(55);
        width: 100%;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 33px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        hyphens: auto;

        &:hover {
            text-decoration: none;

            h3 {
                text-decoration: underline;
            }
        }

        h3,
        h5 {
            color: #fff;
            white-space: normal;
        }

        h3 {
            margin-right: 20%;
            margin-bottom: 0;
        }
    }

    &__link {
        position: absolute;
        bottom: 33px;
        right: 0;
        width: 74px;

        img {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            padding: 51px;
        }

        &__image {
            @include clip-right(85);
        }

        &__link {
            width: 80px;
            bottom: 51px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__content {
            padding: 60px;
        }

        &__image {
            @include clip-right(102);
        }

        &__link {
            width: 94px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__content {
            padding: 80px;
        }

        &__image {
            @include clip-right(142);
        }

        &__link {
            width: 132px;
            bottom: 80px;
        }
    }
}
